import React from "react";
import GContainer from "../components/GContainer";
import GLink from "../components/GLink";
import MatchPoints from "../components/MatchPoints";
import { Typography, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";

//icons
import imDataManNext from "../images/datamanNext.png";
import imDataManPro from "../images/datamanPro.png";
import imDataManEn from "../images/datamanEn.png";
import imWifiMan from "../images/wifiman9.png";
import imDataManCN from "../images/datamanCN.png";
import imDonkey from "../images/donkeyapp.png";
import imEminus from "../images/eminus.png";
import imSinmoji from "../images/sinmoji.png";
import imWTT from "../images/wtt.png";
import imWTA from "../images/wta.png";
import imWTB from "../images/wtb.png";
import imWTM from "../images/wtm.png";
import imVlock from "../images/vlock.png";
import imDribble from "../images/dribble.png";
import imTimeLimit from "../images/gametimelimit.png";
import imEyecare from "../images/eyecare.png";
import imBong from "../images/bongxtreme.png";
import imAicon from "../images/aicon.png";
import imCatchme from "../images/mycatchme.png";
import imFlood from "../images/floodalert.png";
import imGeoring from "../images/georing.png";
import imJabme from "../images/jabme.png";
import imTxtagif from "../images/txtagif.png";
import imVoiceman from "../images/voiceman.png";

//last
import Box from "@material-ui/core/Box";

function MediaItem(props) {
  const isInternal = /^\/(?!\/)/.test(props.url);
  const aTab = isInternal ? {} : { newTab: "true" };

  return (
    <Grid item xs={12} sm={6}>
      <Box textAlign="center" maxHeight={210}>
        <img src={props.icon} width="146" />
      </Box>
      <Box textAlign="center" pb={4}>
        <Typography variant={MatchPoints({ xs: "h4" })} paragraph>
          {props.title}
        </Typography>
        <Typography variant="body1" paragraph>
          {props.children}
        </Typography>
        <GLink {...aTab} url={props.url}>
          Learn more
        </GLink>
      </Box>
    </Grid>
  );
}

export default function App() {
  return (
    <Box pt="4rem" pb="6rem" style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>XVision - We Invent Apps</title>
      </Helmet>

      <GContainer>
        <Box pb="5rem">
          <Typography variant="h1" align="center" paragraph>
            We Invent Apps.
          </Typography>
        </Box>

        <Grid container spacing={10}>
          <MediaItem icon={imDataManNext} title="DataMan" url="/dataman">
            Simplest and clearest app to track your data usage in real-time on iPhone and Apple
            Watch.
          </MediaItem>

          <MediaItem
            icon={imWifiMan}
            title="WifiMan"
            url="https://itunes.apple.com/us/app/id1054634018?mt=8&at=10l9Ek"
          >
            Automatically track and easily view all your usage stats on one or multiple Wi-Fi
            networks.
          </MediaItem>

          <MediaItem icon={imDataManPro} title="DataMan Pro" url="/dataman">
            Smarter and more powerful. Get push alerts, suggested usage, extended forecasts, and
            more.
          </MediaItem>

          <MediaItem
            icon={imDataManCN}
            title="DataMan 中国"
            url="https://itunes.apple.com/cn/app/id1086090804?mt=8&at=10l9Ek"
          >
            Designed for China.
            专为中国用户度身打造，实时监控套餐内所包含的常规流量和闲时流量，轻松避免额外资费。
          </MediaItem>

          <MediaItem icon={imDataManEn} title="DataMan Enterprise" url="/dataman">
            Brilliant for travelers. Manage multiple data plans and export your stats automatically.
          </MediaItem>

          <MediaItem
            icon={imDonkey}
            title="DONKEY.APP"
            url="https://itunes.apple.com/us/app/donkey.app/id1189683765?mt=8&at=10l9Ek"
          >
            Be retro nerdy cool. Relive the classic DOS game by Bill Gates on your iPhone, Apple Watch, and
            Apple TV.
          </MediaItem>

          <MediaItem
            icon={imSinmoji}
            title="SINmoji"
            url="https://coconuts.co/singapore/get-your-singlish-game-new-singlish-emoji-app/"
          >
            Do Emojis speak? Yes! And they speak Singlish! The only Singlish emoji keyboard in the
            world.
          </MediaItem>

          <MediaItem
            icon={imEminus}
            title="E-minus"
            url="https://itunes.apple.com/us/app/id933278113?mt=8&at=10l9Ek"
          >
            Countdown with special effects on your widget screen. Dazzling animations. Beautiful
            fonts. Free.
          </MediaItem>

          <MediaItem
            icon={imVlock}
            title="vlock"
            url="https://itunes.apple.com/us/app/vlock/id467883637?mt=8&at=10l9Ek"
          >
            Mesmerizingly beautiful video clock. Flaunt your iPhone and iPad. Free.
          </MediaItem>

          <MediaItem
            icon={imTxtagif}
            title="txtAgif"
            url="https://www.macworld.com/article/1146989/txtagif.html"
          >
            Animate your messages. Easily search and add animated GIF to your MMS.
          </MediaItem>

          <MediaItem
            icon={imTimeLimit}
            title="Game Time Limit"
            url="https://www.theguardian.com/media/2010/sep/27/app-iphone-ipad-children-game-limit"
          >
            Monitor and limit your child&apos;s game and screen time. Essential parenting tool.
          </MediaItem>

          <MediaItem
            icon={imEyecare}
            title="EyeCare"
            url="https://www.visioncareproducts.com/apps-for-opticians/"
          >
            Save your vision. Stop eye strain, dry eyes, headaches caused by screen overuse.
          </MediaItem>
          <MediaItem
            icon={imJabme}
            title="JabMe"
            url="https://appadvice.com/appnn/2011/12/jabme-the-hilarious-social-entertainment-app-receives-an-update"
          >
            Start a Jab War! Jab your Game Center, Twitter and Facebook friends with awesome sounds.
          </MediaItem>

          <MediaItem
            icon={imGeoring}
            title="GeoRing"
            url="https://www.cultofmac.com/78515/georing-can-instantly-turn-your-entire-playlist-into-ringtones-new-app/"
          >
            Instantly turn your entire iTunes library into ringtones. Shuffle your ringtones.
            Banned.
          </MediaItem>

          <MediaItem
            icon={imDribble}
            title="donkulous Dribble"
            url="https://www.youtube.com/watch?v=iqwTvdtcDbg"
          >
            More ridonkulous than some Cup in the World. Join the football fever.
          </MediaItem>

          <MediaItem
            icon={imFlood}
            title="Flood Alert"
            url="http://xvisionnow.blogspot.com/2010/08/flood-alert-for-singapore-app-featured.html"
          >
            Rescue your properties from the Flood. Get hyperlocal push notifications.
          </MediaItem>

          <MediaItem
            icon={imVoiceman}
            title="VoiceMan"
            url="https://pogue.blogs.nytimes.com/2012/05/17/how-to-track-your-smartphone-data-usage/"
          >
            Stop voice minute overage charges. Track your used minutes in real time.
          </MediaItem>
          <MediaItem
            icon={imAicon}
            title="Aicon"
            url="http://moreinfo.thebigboss.org/moreinfo/depiction.php?file=aiconDp"
          >
            See all your app icons in a whole new light by adding special effects. Cydia app.
          </MediaItem>

          <MediaItem
            icon={imWTT}
            title="What's That Tune?"
            url="https://itunes.apple.com/us/app/id563443503?mt=8&at=10l9Ek"
          >
            Listen to holiday tunes while beating your friends. Over 3,000 top iTunes songs.
          </MediaItem>
          <MediaItem
            icon={imWTA}
            title="What's That App?"
            url="https://appadvice.com/appnn/2012/07/youve-played-them-used-them-and-read-about-them-but-whats-that-app-again"
          >
            Think you&apos;re an APPS-pert? Race against time to identify the endless stream of
            icons.
          </MediaItem>

          <MediaItem
            icon={imWTM}
            title="What's That Movie?"
            url="https://itunes.apple.com/us/app/id557882297?mt=8&at=10l9Ek"
          >
            Incredibly fun, original, multiplayer trivia game for all movie lovers.
          </MediaItem>

          <MediaItem
            icon={imWTB}
            title="What's That Bird?"
            url="https://appadvice.com/appnn/2012/10/its-a-battle-of-the-tweeters-to-answer-whats-that-bird"
          >
            First-ever multiplayer game for Tweetaholics. Battle of the Tweeters.
          </MediaItem>

          <MediaItem
            icon={imBong}
            title="PongXtreme"
            url="https://www.iphoneincanada.ca/iphone-games/custom-3d-pong-with-pongxtreme-for-iphone/"
          >
            Addictive classic Pong in 3D. Customize more than 12 game parameters.
          </MediaItem>
          
          <MediaItem
            icon={imCatchme}
            title="MyCatchMe"
            url="http://applion.jp/iphone/app/326568230/"
          >
            Design-it-yourself greeting game. Say hello to anyone.
          </MediaItem>
        </Grid>
      </GContainer>
    </Box>
  );
}
